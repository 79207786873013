import { Link, graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Img from "gatsby-image"
import { FaBars } from 'react-icons/fa'
import { IconContext } from "react-icons"

import "../styles/header.css"

const FrontHeader = [
  {
    name:"Home",
    link:"/",
  },
  {
    name:"About",
    link:"/#section1",
  },
  {
    name:"Life Circumnavigation",
    link:"/#section2",
  },
  {
    name:"Trade Triumphs",
    link:"/#section3",
  },
  {
    name:"Talent Apices",
    link:"/#section4",
  },
  {
    name:"Situation Maneuvering",
    link:"/#section5",
  },
  {
    name:"Blog",
    link:"/blog/1",
  },
] 

const BlogHeader = [
  {
    name:"Home",
    link:"/",
  },
  {
    name:"Life",
    link:"/Life/",
  },
  {
    name:"Faith",
    link:"/Faith/",
  },
  {
    name:"Thoughts",
    link:"/Thoughts/",
  },
  {
    name:"Reflections",
    link:"/Reflections/",
  },{
    name:"Art",
    link:"/Art/",
  },{
    name:"Culture",
    link:"/Culture/",
  },{
    name:"Reviews",
    link:"/Reviews/",
  }
]

const Header = (props) =>{
  
  const data = useStaticQuery(graphql`
  query  {
    black: file(relativePath: {eq: "dizBlog.png"}) {
      childImageSharp {
        fixed(width: 90) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    white: file(relativePath: {eq: "diz2.png"}) {
      childImageSharp {
        fixed(width: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
  `)


  
  const HeaderSelector = (props.theme === "navbar nav-top") ? FrontHeader : BlogHeader

  const white = data.white.childImageSharp.fixed
  const black = data.black.childImageSharp.fixed
  
  //console.log(props.theme)
  const [mobl,mobval] = useState("navbar-list")
  
  console.log(mobl)
  return(
    <nav className= {props.theme} >
        <Link className="logo" to="/"><Img fixed={(props.theme === "navbar" ) ? white : white } /></Link>
        <div className= {mobl}>
              {HeaderSelector.map((contents) => (
                <Link onClick={() => {
                  mobval((mobl === "navbar-list responive") ? "navbar-list" : "navbar-list responive" )
                }} to={contents.link}>{ contents.name }</Link>
              ))}
        </div>
        <IconContext.Provider value={{ className: "react-icons" }}>
          <div onClick= {() => {
            mobval((mobl === "navbar-list") ? "navbar-list responive" : "navbar-list")
            }}>
            <FaBars />
          </div>
        </IconContext.Provider>
    </nav>
  )
} 

export default Header