import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, {useState} from "react"

import "../styles/footer.css"

const Footer = (props) =>{

    const [life,lifeval] = useState("dropdown-content")
    const [blog,blogval] = useState("dropdown-content")
    const [soc,socval] = useState("dropdown-content")

     return(<div className={props.theme}>
        <div>
        <hr/>
        <div className="f1">
            <div>
                <ul className="insidelist">
                    <li className="dropdown" onClick= {()=>{
                        let test = (life === "dropdown-content") ? "dropdown-content responsive" : "dropdown-content"
                        lifeval(test)
                    }} >Life Absolute</li>
                    <li className={life} ><Link to="/#section1">About</Link></li>
                    <li className={life} ><Link to="/#section2">Life Circumnavigation</Link></li>
                    <li className={life} ><Link to="/#section3">Trade Triumphs</Link></li>
                    <li className={life} ><Link to="/#section4">Talent Apices</Link></li>
                    <li className={life} ><Link to="/#section5">Situation Maneuvering</Link></li>
                </ul>
            </div>
            <div>
                <ul className="insidelist">
                    <li className="dropdown" onClick= {()=>{
                        let test = (blog === "dropdown-content") ? "dropdown-content responsive" : "dropdown-content"
                        blogval(test)
                    }}>Blog</li>
                    <li className={blog}><Link to="/Life/">Life</Link></li>
                    <li className={blog}><Link to="/Faith/">Faith</Link></li>
                    <li className={blog}><Link to="/Thoughts/">Thoughts</Link></li>
                    <li className={blog}><Link to="/Reflections/">Reflection</Link></li>
                    <li className={blog}><Link to="/Art/">Art</Link></li>
                    <li className={blog}><Link to="/Culture/">Culture</Link></li>
                    <li className={blog}><Link to="/Reviews/">Reviews</Link></li>
                </ul>
            </div>
            <div>
            <ul>
                <li className="dropdown" onClick= {()=>{
                        let test = (soc === "dropdown-content") ? "dropdown-content responsive" : "dropdown-content"
                        socval(test)
                    }}>Social</li>
                <li className={soc} >Facebook</li>
                <li className={soc} >Twitter</li>
                <li className={soc} >Linkedin</li>
            </ul>        
            </div>
        </div>
        <hr/>
        <div className="f2">
            <div>Copyright © 2020 Live Absolute All rights reserved.</div>
            <ul>
                <li>Sitemap</li>
                <li>Public Policy</li>
                <li>Legal</li>
            </ul>
        </div>
        </div>                          
    </div>
    )
}

export default Footer