// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import SEO from "../components/seo"
import Header from "../components/header"
import Footer from "../components/footer"

import "../components/layout.css"
import dizBlog from "../images/dizBlog.png"

const Blog = ({data}) =>{
 
        

        console.log(data)
        
return(   
          <div className="font">
          <Header theme = "navbar1" />
          <SEO title={(data.allWordpressPost.edges[0] == null )? "Author" : data.allWordpressPost.edges[0].node.author.name} />    
          <div style={{
              top: '10%',
              height: '80px',
            }} ></div>
          <h1 id="cat" style={{
                  fontSize: '5vw',
                  textAlign: 'center',
                  color: 'white',
                  background: 'black',  
                  margin: '5% 0% 0% 0% 0%', 
                  whiteSpace: 'nowrap'
          }} >{(data.allWordpressPost.edges[0] == null )? "Author" : data.allWordpressPost.edges[0].node.author.name+"'s blogs"}</h1>
          <div className="blogformat" >
          {data.allWordpressPost.edges.map(({ node }) =>(
                      <div className="blog" key={node.slug}>
                              <Link className={(node.tags === null) ? "title" : (node.tags[0].name == "malayalam" )? "maltitle": " "} to={node.slug} to={node.slug}>
                                <div>
                                  <h2>{node.title}</h2>
                                  <Link className="authndcat" to={node.author.name}><p>{node.author.name}</p></Link>
                                  <Link className="authndcat" to={node.categories[0].name}><p>{node.categories[0].name}</p></Link>
                                  <p>{node.date}</p>
                                </div>
                                <h4 dangerouslySetInnerHTML={{__html: node.excerpt}} ></h4>
                                <Img fluid={(node.featured_media === null )? data.black.childImageSharp.fluid : node.featured_media.localFile.childImageSharp.fluid}/>
                              </Link>          
                      </div>    
                  ))}
          </div>
          <Footer theme = "footer1" />
        </div>       
        )
               
} 
export default Blog    

export const pageQuery = graphql`
        query ($name: String!) {
            allWordpressPost(filter: {author: {name: {eq: $name}}}) {
              edges {
                node {
                  id
                  date(formatString: "DD/MM/YY")
                  title
                  slug
                  excerpt
                  featured_media {
                    localFile {
                      childImageSharp {
                        fluid (maxWidth:1000) {
                          ...GatsbyImageSharpFluid_tracedSVG
                        }
                      }
                    }
                  }
                  author {
                    name
                    id
                  }
                  categories {
                    name
                    id
                  }
                  tags {
                    name
                  }
                }
              }
            }
            black: file(relativePath: {eq: "dribble first-01.jpg"}) {
              childImageSharp {
                fluid (maxWidth:1000) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }          
        `